<template>
  <div id="quotation-list">
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-row> 
              <b-col xl="4" sm="12">
                  <b-form-group label="Proyecto" label-for="project">
                    <v-select
                      v-model="filtersForm.project"
                      label="name"
                      placeholder="Selecciona un proyecto"
                      :options="projects"
                      :reduce="projects => projects.id">
                      <span slot="no-options">
                        No se encontraron opciones
                      </span>
                    </v-select>
                  </b-form-group>
                </b-col>
 
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-2">
        <b-card no-body class="mb-0">
          <div class="m-2">
            <b-overlay
              :show="overlayList"
              opacity="1.0"
              spinner-small
              spinner-variant="primary">
              <div class="d-flex align-items-end justify-content-end">
                <div>  
                  <b-button variant="primary"
                    @click.prevent="_createComments()">
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-50"/>
                    <span class="align-middle">
                      Nuevo Comentario
                    </span>
                  </b-button>
                </div>
              </div>
              <div class="mt-2">
                <b-table
                  hover
                  striped
                  show-empty
                  responsive="xl"
                  class="position-relative mb-1"
                  :items="comments.data"
                  :fields="tableColumns"
                  empty-text="Datos no Disponibles">

                <template #cell(comment)="data">
                  <span v-html="data.item.comment"></span>
                </template>

                <template #cell(project)="data">
                  {{ data.item.project.name }}
                </template>
                
                <template #cell(created_at)="data">
                  {{ data.item.created_at | moment("DD/MM/YYYY") }}
                </template>

                <template #cell(actions)="data">
                  <b-dropdown variant="link" no-caret
                    :right="$store.state.appConfig.isRTL">
                    <template #button-content>
                      <feather-icon size="16"
                        icon="MoreVerticalIcon"
                        class="align-middle text-body"/>
                    </template>

                    <b-dropdown-item @click.prevent="_editNew(data.item)" v-if="permissions_visible.edit_news">
                      <feather-icon icon="EditIcon"/>
                      <span class="align-middle ml-50">Editar</span>
                    </b-dropdown-item> 
                  </b-dropdown>
                </template>

              </b-table>
                <b-pagination
                  align="right"
                  v-model="currentPage"
                  hide-goto-end-buttons
                  :total-rows="comments.total"
                  :per-page="comments.per_page"
                  />
              </div>
            </b-overlay>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapState} from 'vuex'
  import ToastificationContent from "@core/components/toastification/ToastificationContent";

  export default {
    name: 'CommentsList',
    data () {
      return {
        is_admin:false,
        permissions_visible:{
          edit_preapprovals:false,
          show_preapprovals:false,
          download_preapprovals:false,
          edit_news:false,
        },
        overlayForm: false,
        overlayList: true,
        currentPage: 1,
        filtersForm: { 
          project: '',
          page: 1, 
        },
        statusList: [
          {id:'1',name:'Visible'},
          {id:'0',name:'Oculto'},
        ],
        tableColumns: [
          {key: 'id', sortable: false, label: 'ID'},
          {key: 'name', sortable: true, label: 'Nombre'},
          {key: 'comment', sortable: true, label: 'Comentario'},
          {key: 'project', sortable: true, label: 'Proyecto'},
          {key: 'created_at', sortable: true, label: 'Fecha'},
          {key: 'actions', sortable: false, label: 'Acciones'},
        ]
      }
    },
    mounted(){
      this.is_admin = this.$PermissionHelper.getRole().toLowerCase() == 'admin';
      this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
      if(this.is_admin){
        this.permissions_visible = {
          edit_preapprovals:true,
          show_preapprovals:true,
          download_preapprovals:true,
          edit_news:true,
        };
      }
    },
    beforeMount() {
      // this.filtersForm.page = this.currentPage
      this._fetchComments(this.filtersForm)
    },
    watch: {
      "filtersForm.project": {
        handler(newval){
          this._fetchComments(this.filtersForm)
        }
      },
      currentPage (val) {
        this.filtersForm.page = this.currentPage
        this._fetchComments(this.filtersForm)
      }
    },
    computed: {
      ...mapState('comments',['comments']),
      ...mapGetters('profile',['projects']),
    },
    methods: {
      ...mapActions('news',['changeVisible']),
      ...mapActions('comments',['fetchComments']),
      async _fetchComments(filter) {
        this.overlayList = true
        await this.fetchComments(filter)
        .then((res) => {
           
        })  
        .finally(() => {
          this.overlayList = false
        })
      },

       async _changeVisibleData(comments) {
        let visibleForm = {id: comments.id, status: comments.visible === true ? '1':'0'}
        await this.changeVisible(visibleForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Estado modificado con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Problemas al modificar el estado',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              })
          })
      },
      _editNew(commentObj) {
        let data = { name: 'comments-details', params: { id: commentObj.id, commentsingle: commentObj }};
        this.$router.push( data )
      },
      _colorBadge (data) {
        switch (data) {
          case '1':
            return 'light-success'
          case '0':
            return 'light-warning'
          case '2':
            return 'light-danger'
        }
      },
      _createComments() {
        this.$router.push({ name: 'comments-create' })
      },
    },
  }
</script>
