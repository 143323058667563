<template>
  <div id="comments">
    <comments-list></comments-list>
  </div>
</template>

<script>
  import {mapActions} from 'vuex'
  import CommentsList from '../comments/components/CommentsList.vue'

  export default{
    name: 'Comments',
    components: {CommentsList},
    beforeMount() {
      this.fetchProjects()
    },
    methods: {
      ...mapActions('profile',['fetchProjects']),
    },
  }
</script>
